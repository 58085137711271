import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
    ogImage: file(relativePath: { eq: "ogImage.jpg" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`

const Seo = ({ title, description, metaImage }) => {
  const { site, ogImage } = useStaticQuery(query)

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  metaImage = metaImage || ogImage.childImageSharp.resize

  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  return (
    <Helmet
      // TODO set lang attribute when using localization
      htmlAttributes={{ lang: 'en' }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: metaTitle
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: metaTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(
        image
          ? [
              {
                property: 'og:image',
                content: image
              },
              {
                property: 'og:image:width',
                content: metaImage.width
              },
              {
                property: 'og:image:height',
                content: metaImage.height
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image'
              }
            ]
          : [
              {
                name: 'twitter:card',
                content: 'summary'
              }
            ]
      )}
    />
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metaImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  })
}

export default Seo
